<template>
    <div 
        class="choicecard"
        :class="{
            'selected-choicecard': selected
        }" 
    >
        <img 
            :id="`choice_img_${this.choiceID}`"
            :src="imgsrc" v-if="imgsrc"
        />
        <div 
            class="choicecard-icon-container" 
            :class="{
                'show': selected
            }"
        >
            <svg 
                class="choicecard-icon"
                xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"
            >
                <path fill="#000" d="M10 20c-1.978 0-3.911-.587-5.556-1.685-1.644-1.1-2.926-2.66-3.683-4.488C.004 12-.194 9.989.192 8.049.578 6.11 1.53 4.327 2.93 2.93 4.327 1.53 6.109.578 8.049.192c1.94-.386 3.95-.188 5.778.57 1.827.756 3.389 2.038 4.488 3.682C19.413 6.09 20 8.022 20 10c-.003 2.651-1.057 5.193-2.932 7.068S12.65 19.998 10 20zM4.41 8.59L3 10l5 5 9-9-1.41-1.42L8 12.17 4.41 8.59z"/>
            </svg>
        </div>
        <p class="choicecard-labelname">{{labelName}}</p>
    </div>
</template>

<script>

export default {
    name: 'ChoiceCard', 
    props:{
        choiceID:{
            type: Number
        },
        imgsrc:{
            type: String,
            required: true,
        },
        labelName:{
            type: String
        },
        selected:{
            type: Boolean,
        }
    },
    async mounted(){
        var choicecard = document.getElementById(`choice${this.choiceID}`)
        var img_html = document.getElementById(`choice_img_${this.choiceID}`)
        // console.log(img.naturalWidth, img.naturalHeight, 'sure')
        // var realWidth = img.naturalWidth;
        // var realHeight = img.naturalHeight;
        // console.log(realWidth,realHeight,"this")
        let img = new Image();
        img.onload = function () {
            // console.log(img.naturalWidth, img.naturalHeight);
            if(img.naturalHeight > choicecard.clientHeight){
                img_html.classList.add("padding-class")
            }else if(img.naturalWidth > choicecard.clientWidth){
                img_html.classList.add("padding-class")
            }else if(img.naturalHeight > choicecard.clientHeight && img.naturalWidth > choicecard.clientWidth){
                img_html.classList.add("padding-class")
            }else{
                img_html.classList.add("choicecard-img")
            }
        };
        img.src = this.imgsrc;
        
    }
}
</script>
<style lang="scss">
@import './ChoiceCard.scss';
</style>
