var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"question-container-wrapper"},[_c('div',{staticClass:"question-container"},[_c('div',{staticClass:"question-cols"},[_c('div',{staticClass:"col-left"},[_c('div',{staticClass:"content-left"},[_c('h1',{staticClass:"Heading"},[_vm._v(" "+_vm._s(_vm.question.question_data.text)+" ")]),(_vm.question.question_data.subtext)?_c('p',{staticClass:"subHeading"},[_vm._v(" "+_vm._s(_vm.question.question_data.subtext)+" ")]):_vm._e()])]),_c('div',{staticClass:"col-right"},[(_vm.question.question_data.question_type == 'TB')?[_c('div',{staticClass:"question-tb"},[_c('Input',{attrs:{"labelName":_vm.textboxName,"placeholder":"Type here","error":_vm.textErr,"msg":_vm.textmsg,"noerror":true},on:{"input":_vm.checkInputText},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)]:_vm._e(),(_vm.question.question_data.question_type == 'CH')?[_c('div',{staticClass:"selectchoice"},[_c('div',{staticClass:"selectchoice-row"},_vm._l((_vm.question.question_data
                                        .choice_data),function(choice){return _c('div',{key:choice.id,staticClass:"selectchoice-img-container",attrs:{"id":choice.id},on:{"click":_vm.select}},[(_vm.answer == null)?[_c('ChoiceCard',{attrs:{"id":'choice' + choice.id,"imgsrc":choice.choice_image
                                                    ? choice.choice_image
                                                    : '',"labelName":choice.text,"choiceID":choice.id}})]:[(_vm.answer == choice.id)?[_c('ChoiceCard',{attrs:{"id":'choice' + choice.id,"imgsrc":choice.choice_image
                                                        ? choice.choice_image
                                                        : '',"labelName":choice.text,"choiceID":choice.id,"selected":""}})]:[_c('ChoiceCard',{attrs:{"id":'choice' + choice.id,"imgsrc":choice.choice_image
                                                        ? choice.choice_image
                                                        : '',"labelName":choice.text,"choiceID":choice.id}})]]],2)}),0)])]:_vm._e(),(_vm.question.question_data.question_type == 'RA')?[_c('div',{staticClass:"price-range"},[_c('h2',[_vm._v("Your Price Range")]),_c('p',[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem(_vm.min))+" - ₹ "+_vm._s(_vm.convertToIndianNumberSystem(_vm.max))+" ")]),(_vm.rangeAnswer != null)?[(_vm.rangeChoices[0])?_c('Range',{attrs:{"min":_vm.rangeChoices[0].min_value,"max":_vm.rangeChoices[0].max_value,"min_value":_vm.rangeAnswer.min,"max_value":_vm.rangeAnswer.max},on:{"min":_vm.updatemin,"max":_vm.updatemax}}):_vm._e()]:[(_vm.rangeChoices[0])?_c('Range',{attrs:{"min":_vm.rangeChoices[0].min_value,"max":_vm.rangeChoices[0].max_value},on:{"min":_vm.updatemin,"max":_vm.updatemax}}):_vm._e()]],2)]:_vm._e(),(_vm.question.question_data.question_type == 'LC')?[_c('div',{staticClass:"project-details"},[(_vm.PD)?_c('div',{staticClass:"project-details-1"},[_c('p',[_vm._v("Your Location")]),_c('div',{staticClass:"radio-button-wrapper"},[(
                                            _vm.projectAnswer.projectLocation !=
                                                ''
                                        )?_vm._l((_vm.projectChoices),function(choice,index){return _c('RadioButton',{key:((choice.text) + "_" + index),staticClass:"radio",attrs:{"name":choice.text,"id":("" + (choice.text)),"selected":_vm.projectAnswer.projectLocation ==
                                                choice.text
                                                    ? true
                                                    : false},nativeOn:{"click":function($event){return _vm.selectedOneProjectChoices($event)}}})}):_vm._l((_vm.projectChoices),function(choice,index){return _c('RadioButton',{key:((choice.text) + "_" + index),staticClass:"radio",attrs:{"name":choice.text,"id":("" + (choice.text))},nativeOn:{"click":function($event){return _vm.selectedOneProjectChoices($event)}}})})],2),(_vm.projectLocations.length > 0)?_c('div',[_c('div',{staticClass:"project-select"},[_c('div',{staticClass:"project-dropdown"},[(
                                                    _vm.projectAnswer.projectName !=
                                                        '' &&
                                                        _vm.projectAnswer.houseNumber ==
                                                            ''
                                                )?[_c('Select',{attrs:{"choices":_vm.projectLocations,"default-select":_vm.projectAnswer.projectName},on:{"valueEmit":_vm.address}})]:[_c('Select',{attrs:{"choices":_vm.projectLocations},on:{"valueEmit":_vm.address}})]],2),_c('div',{staticClass:"house-number"},[_c('Input',{attrs:{"labelName":"House Number","error":_vm.hNoErr,"msg":_vm.hNomsg,"noerror":true},model:{value:(_vm.houseNumber),callback:function ($$v) {_vm.houseNumber=$$v},expression:"houseNumber"}})],1)]),_c('div',{staticClass:"change"},[_c('h1',[_vm._v(" Could not find your project or location? ")]),_c('p',{on:{"click":_vm.changeUI1}},[_vm._v(" Enter Your Address Here Instead ")])])]):_vm._e()]):_c('div',{staticClass:"project-details-2"},[_c('Input',{staticClass:"house-number",attrs:{"labelName":"Your House Address","error":_vm.addrErr,"msg":_vm.addrmsg,"noerror":true},model:{value:(_vm.enteredAddress),callback:function ($$v) {_vm.enteredAddress=$$v},expression:"enteredAddress"}}),_c('div',{staticClass:"change"},[_c('h1',[_vm._v("Clicked it by mistake?")]),_c('p',{on:{"click":_vm.changeUI2}},[_vm._v(" Find Your Project Here ")])])],1)])]:_vm._e()],2)]),_c('div',{staticClass:"question-footer"},[_c('Button',{staticClass:"save-button",attrs:{"primary":"","name":"Save Changes","disabled":_vm.disabled},nativeOn:{"click":function($event){return _vm.updateDetails($event)}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }