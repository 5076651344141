<template>
    <div class="user-profile-page">
        <template
            v-if="
                Object.keys(profileData).length > 0 &&
                    Object.keys(projectData).length > 0
            "
        >
            <div class="user-profile">
                <div class="user-info">
                    <div
                        class="user-details"
                        v-if="Object.keys(profileData).length > 0"
                    >
                        <div class="user-details-header">
                            <h1 class="user-details-heading">
                                Personal Details
                            </h1>
                            <div class="user-edit-button">
                                <span
                                    class="edit-btn"
                                    @click="showModal = true"
                                >
                                    <svg
                                        width="25"
                                        height="24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.025 21h-3.75v-3.75l11.06-11.058 3.75 3.75L7.025 21zm12.13-12.128-3.75-3.75 1.83-1.83a1 1 0 0 1 1.41 0l2.34 2.34a1 1 0 0 1 0 1.41l-1.83 1.83z"
                                            fill="#acacac"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>

                        <div class="saved-user-details">
                            <div class="user-attribute">
                                <p class="attribute-label">Name</p>
                                <p class="attribute-value">
                                    {{ profileData.first_name }}
                                </p>
                            </div>
                            <div class="user-attribute">
                                <p class="attribute-label">Email</p>
                                <p class="attribute-value" id="emailid">
                                    {{ profileData.email_id }}
                                </p>
                            </div>
                            <div class="user-attribute">
                                <p class="attribute-label">Phone no</p>
                                <p class="attribute-value">
                                    {{ profileData.phone_number }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="myproject"
                        v-if="
                            projectData.is_pq_completed == true &&
                                projectData.user_unit_list.length > 0
                        "
                    >
                        <div class="myproject-header">
                            <h1 class="myproject-title">Your Projects</h1>
                            <!-- <a
                                :href="
                                    `${url}?startQuiz=true&survey_type=PQ&t_id=${themeId}&id=1`
                                "
                            >
                                <Button primary name="Create New Project" />
                            </a> -->
                            <p class="view-all" @click="openProjectModal">
                                View All
                            </p>
                        </div>
                        <div
                            :class="
                                projectData.user_unit_list.length == 1
                                    ? 'myproject-single'
                                    : 'myproject-list-container'
                            "
                        >
                            <div
                                v-for="(project,
                                index) in projectData.user_unit_list.slice(
                                    0,
                                    2,
                                )"
                                :key="`myproject${index}`"
                            >
                                <div class="myproject-card">
                                    <div class="project-actions">
                                        <!-- <div class="edit-project" @click="editProject">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.189" height="15.134" viewBox="0 0 16.189 15.134">
                                                <g id="icon_content_create_24px" data-name="icon/content/create_24px" transform="translate(4.32 -0.68)">
                                                    <path id="_Color" data-name=" ↳Color" class="cls-1" d="M3.373,15.134H0V11.981l9.947-9.3,3.373,3.153Zm10.908-10.2h0L10.909,1.784,12.555.246a.941.941,0,0,1,1.268,0l2.1,1.967a.8.8,0,0,1,0,1.186L14.282,4.936Z" transform="translate(-4.32 0.68)"/>
                                                </g>
                                            </svg>

                                        </div> -->
                                        <!-- <div class="delete-project" @click="openDeleteProject(project.id)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14.962" height="15.133" viewBox="0 0 14.962 15.133">
                                                <g id="noun_Delete_744231" transform="translate(-15 -15.1)">
                                                    <path id="Path_807" data-name="Path 807" class="cls-1" d="M29.321,16.874H25.687v-.791a1.068,1.068,0,0,0-1.154-.983h-4.1a1.068,1.068,0,0,0-1.154.983v.791H15.641a.641.641,0,1,0,0,1.282h.791l.791,9.961a2.139,2.139,0,0,0,2.137,2.116h6.22a2.139,2.139,0,0,0,2.137-2.116l.812-9.939h.791a.63.63,0,0,0,.641-.641A.648.648,0,0,0,29.321,16.874Zm-8.764-.492H24.4v.492H20.557Zm.256,10.409h-.021a.654.654,0,0,1-.641-.62l-.235-5.215a.618.618,0,0,1,.62-.663h.021a.654.654,0,0,1,.641.62l.235,5.215A.657.657,0,0,1,20.814,26.792Zm4-.62a.654.654,0,0,1-.641.62h-.021a.657.657,0,0,1-.62-.663l.235-5.215a.654.654,0,0,1,.641-.62h.021a.657.657,0,0,1,.62.663Z" transform="translate(0)"/>
                                                </g>
                                            </svg>

                                        </div> -->
                                    </div>
                                    <div class="myproject-img-name-wrapper">
                                        <img
                                            :src="
                                                project.image
                                                    ? project.image.s_image
                                                    : require('@/assets/images/default-image.png')
                                            "
                                            :alt="
                                                project.project_name
                                                    ? project.project_name
                                                    : 'Project ' + index
                                            "
                                        />
                                        <p class="project-name">
                                            {{
                                                project.project_name
                                                    ? project.project_name
                                                    : 'Project ' + index
                                            }}
                                        </p>
                                    </div>
                                    <div class="myproject-detail-wrapper">
                                        <div class="myproject-detail">
                                            <div class="myproject-detail-col">
                                                <span> House Details </span>
                                                <p class="project-attr">
                                                    {{ project.house_details }}
                                                </p>
                                            </div>
                                            <div class="myproject-detail-col">
                                                <span> House type </span>
                                                <p class="project-attr">
                                                    {{
                                                        project.house_type
                                                            ? project.house_type
                                                            : '-'
                                                    }}
                                                </p>
                                            </div>
                                            <div class="myproject-detail-col">
                                                <span> Family </span>
                                                <p class="project-attr">
                                                    {{
                                                        project.family
                                                            ? project.family
                                                            : '-'
                                                    }}
                                                </p>
                                            </div>
                                            <div class="myproject-detail-col">
                                                <span> Budget </span>
                                                <p class="project-attr">
                                                    {{ project.range }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Modal
                                    @close="closeProjectModal"
                                    v-if="projectModal"
                                    transparent
                                >
                                    <div class="project-modal">
                                        <p class="project-modal-header">
                                            Your projects
                                        </p>

                                        <div class="project-modal-container">
                                            <template
                                                v-if="
                                                    projectData.user_unit_list
                                                        .length > 0
                                                "
                                            >
                                                <div
                                                    v-for="(project,
                                                    index) in projectData.user_unit_list"
                                                    :key="`myproject${index}`"
                                                >
                                                    <div class="myproject-card">
                                                        <div
                                                            class="project-actions"
                                                        >
                                                            <!-- <div class="edit-project" @click="editProject">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.189" height="15.134" viewBox="0 0 16.189 15.134">
                                                                    <g id="icon_content_create_24px" data-name="icon/content/create_24px" transform="translate(4.32 -0.68)">
                                                                        <path id="_Color" data-name=" ↳Color" class="cls-1" d="M3.373,15.134H0V11.981l9.947-9.3,3.373,3.153Zm10.908-10.2h0L10.909,1.784,12.555.246a.941.941,0,0,1,1.268,0l2.1,1.967a.8.8,0,0,1,0,1.186L14.282,4.936Z" transform="translate(-4.32 0.68)"/>
                                                                    </g>
                                                                </svg>

                                                            </div> -->
                                                            <!-- <div class="delete-project" @click="openDeleteProject(project.id)">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.962" height="15.133" viewBox="0 0 14.962 15.133">
                                                                    <g id="noun_Delete_744231" transform="translate(-15 -15.1)">
                                                                        <path id="Path_807" data-name="Path 807" class="cls-1" d="M29.321,16.874H25.687v-.791a1.068,1.068,0,0,0-1.154-.983h-4.1a1.068,1.068,0,0,0-1.154.983v.791H15.641a.641.641,0,1,0,0,1.282h.791l.791,9.961a2.139,2.139,0,0,0,2.137,2.116h6.22a2.139,2.139,0,0,0,2.137-2.116l.812-9.939h.791a.63.63,0,0,0,.641-.641A.648.648,0,0,0,29.321,16.874Zm-8.764-.492H24.4v.492H20.557Zm.256,10.409h-.021a.654.654,0,0,1-.641-.62l-.235-5.215a.618.618,0,0,1,.62-.663h.021a.654.654,0,0,1,.641.62l.235,5.215A.657.657,0,0,1,20.814,26.792Zm4-.62a.654.654,0,0,1-.641.62h-.021a.657.657,0,0,1-.62-.663l.235-5.215a.654.654,0,0,1,.641-.62h.021a.657.657,0,0,1,.62.663Z" transform="translate(0)"/>
                                                                    </g>
                                                                </svg>

                                                            </div>  -->
                                                        </div>
                                                        <div
                                                            class="myproject-img-name-wrapper"
                                                        >
                                                            <img
                                                                :src="
                                                                    project.image
                                                                        ? project
                                                                              .image
                                                                              .s_image
                                                                        : require('@/assets/images/default-image.png')
                                                                "
                                                                :alt="
                                                                    project.project_name
                                                                        ? project.project_name
                                                                        : 'Project ' +
                                                                          index
                                                                "
                                                            />
                                                            <p
                                                                class="project-name"
                                                            >
                                                                {{
                                                                    project.project_name
                                                                        ? project.project_name
                                                                        : 'Project ' +
                                                                          index
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="myproject-detail-wrapper"
                                                        >
                                                            <div
                                                                class="myproject-detail"
                                                            >
                                                                <div
                                                                    class="myproject-detail-col"
                                                                >
                                                                    <span>
                                                                        House
                                                                        Details
                                                                    </span>
                                                                    <p
                                                                        class="project-attr"
                                                                    >
                                                                        {{
                                                                            project.house_details
                                                                        }}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    class="myproject-detail-col"
                                                                >
                                                                    <span>
                                                                        House
                                                                        type
                                                                    </span>
                                                                    <p
                                                                        class="project-attr"
                                                                    >
                                                                        {{
                                                                            project.house_type
                                                                                ? project.house_type
                                                                                : '-'
                                                                        }}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    class="myproject-detail-col"
                                                                >
                                                                    <span>
                                                                        Family
                                                                    </span>
                                                                    <p
                                                                        class="project-attr"
                                                                    >
                                                                        {{
                                                                            project.family
                                                                                ? project.family
                                                                                : '-'
                                                                        }}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    class="myproject-detail-col"
                                                                >
                                                                    <span>
                                                                        Budget
                                                                    </span>
                                                                    <p
                                                                        class="project-attr"
                                                                    >
                                                                        {{
                                                                            project.range
                                                                        }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                No Additional Project Data
                                            </template>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <template v-else>
                        <div class="profile-onboarding">
                            <p>Complete PQ to create your first project</p>

                            <a :href="`${url}?startQuiz=true&survey_type=PQ`">
                                <Button
                                    class="onboarding-btn"
                                    primary
                                    name="Complete Onboarding"
                                />
                            </a>
                        </div>
                    </template>
                </div>

                <Modal @close="closeEditUserModal" v-if="showModal" transparent>
                    <div class="edit-dialog">
                        <div class="edit-details-heading">
                            Personal Details
                        </div>
                        <div class="saved-user-details">
                            <div class="user-attribute">
                                <p class="attribute-label">Name</p>
                                <p class="attribute-value">
                                    {{ profileData.first_name }}
                                </p>
                            </div>
                            <div class="user-attribute">
                                <p class="attribute-label">Phone no</p>
                                <p class="attribute-value">
                                    {{ profileData.phone_number }}
                                </p>
                            </div>
                            <div class="user-attribute">
                                <p class="attribute-label">Email</p>
                                <Input
                                    class="edit-textbox"
                                    v-model="email"
                                    placeholder="Type here"
                                    :error="emailErr"
                                    :msg="emailmsg"
                                />
                            </div>
                        </div>
                        <div class="button-center">
                            <Button
                                class="save-button"
                                primary
                                name="Save Changes"
                                @click.native="updateDetails"
                                :disabled="disabled"
                            />
                        </div>
                    </div>
                </Modal>

                <div
                    class="user-style"
                    v-if="
                        projectData.is_sq_completed == true &&
                            Object.keys(projectData.theme_data).length != 0
                    "
                >
                    <SQResultContainer
                        :calculatedResult="projectData.theme_data"
                        :url="`${url}?startQuiz=true&fq=true`"
                        :toggleThemeModal="toggleThemeModal"
                    />
                    <!-- <img
                        class="style-img"
                        :src="
                            profileData.theme_image
                                ? profileData.theme_image.p_image
                                : 'https://rift-uat.s3.amazonaws.com/photos/cache/47/3f/473f681d8fc87c09772cd1ac50321c2a.jpg'
                        "
                        :alt="profileData.theme_name"
                    />
                    <div class="user-style-name">
                        <p class="style-name">
                            Your Style is
                            {{ profileData.theme_name }}
                        </p>
                        <a :href="`${url}?startQuiz=true&fq=true`">
                            <span class="retake-sq">Retake</span>
                        </a>
                    </div> -->
                </div>
                <div v-else class="user-style" style="text-align: center;">
                    <p class="discover">Discover your style</p>
                    <a :href="`${url}?startQuiz=true`" style="color: #ff6100;"
                        >Complete the Quiz</a
                    >
                </div>
            </div>

            <div
                class="project-questions"
                v-if="
                    projectQuestions.payload.questions.length > 0 &&
                        projectData.is_pq_completed == true
                "
            >
                <p class="quiz-result">Quiz Result</p>
                <div
                    v-for="(question,
                    index) in projectQuestions.payload.questions.filter(
                        value => value.question_data.question_type !== 'FP',
                    )"
                    :key="index"
                    class="question-answer-container"
                >
                    <div class="project-question">
                        {{ question.question_data.text }}
                    </div>
                    <div class="project-answer">
                        <p v-if="question.answer_data.response">
                            {{ answers[index] }}
                        </p>
                        <p v-else>Not answered</p>
                        <div
                            class="edit-project-answer"
                            @click="editProjectAnswer(question)"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.189"
                                height="15.134"
                                viewBox="0 0 16.189 15.134"
                            >
                                <g
                                    id="icon_content_create_24px"
                                    data-name="icon/content/create_24px"
                                    transform="translate(4.32 -0.68)"
                                >
                                    <path
                                        id="_Color"
                                        data-name=" ↳Color"
                                        class="cls-1"
                                        d="M3.373,15.134H0V11.981l9.947-9.3,3.373,3.153Zm10.908-10.2h0L10.909,1.784,12.555.246a.941.941,0,0,1,1.268,0l2.1,1.967a.8.8,0,0,1,0,1.186L14.282,4.936Z"
                                        transform="translate(-4.32 0.68)"
                                    />
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <Modal v-if="showDeleteProject" @close="closeDeleteProject" transparent>
            <div class="delete-modal">
                <div class="delete-modal-view">
                    <p>Are you sure you want to delete this project?</p>
                    <div
                        class="result-button"
                        style="margin-top: 20px; display: flex; justify-content: center;"
                    >
                        <Button
                            class="delete-draft"
                            name="yes"
                            outline
                            @click.native="deleteProjectResult()"
                        />
                        <Button
                            style="margin-left:20px;"
                            class="override"
                            name="no"
                            primary
                            @click.native="closeDeleteProject"
                        />
                    </div>
                </div>
            </div>
        </Modal>

        <Modal @close="toggleThemeModal" v-if="themeModal" transparent>
            <div class="theme-modal">
                <h1>{{ themeModalName }}</h1>
                <p>{{ themeModalDescription }}</p>
            </div>
        </Modal>
        <Modal @close="closeQuestionModal" v-if="editAnswer" transparent>
            <QuestionContainer
                :question="questionData"
                :projectLocations="projLocatn"
                @closeModal="closeQuestionModal"
                @updateQuestions="updateProjectQuestions"
                @getProjectsOnLocation="getProjectsOnLocation"
            />
        </Modal>
    </div>
</template>

<script>
// import Modal from 'componentsv2/Modal'
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import UserProfileModule from 'store/modules/UserProfilePage'
import ConvertToPrice from 'mixins/ConvertToPrice'
import Input from 'componentsv2/Input'
import Button from 'componentsv2/Button'
import Loader from 'componentsv2/Loader'
import Modal from 'componentsv2/Modal'
import SQResultContainer from 'componentsv2/SQResultContainer'
import QuestionContainer from 'componentsv2/QuestionContainer'
import LoaderHandler from 'mixins/loader'

export default {
    name: 'MyProfile',
    props: ['profileData', 'projectData'],
    mixins: [RegisterStoreModule, ConvertToPrice, LoaderHandler],
    components: {
        Input,
        Button,
        Modal,
        Loader,
        SQResultContainer,
        QuestionContainer,
    },
    data() {
        return {
            onSubmit: false,
            showModal: false,
            dob: null,
            gender: null,
            address: null,
            url:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_QUIZ_BASE_URL_B2B
                    : process.env.VUE_APP_QUIZ_BASE_URL,

            disabled: true,
            email: null,
            emailErr: null,
            emailmsg: '',
            themeModal: false,
            themeModalName: '',
            themeModalDescription: '',
            projectModal: false,
            showDeleteProject: false,
            editAnswer: false,
            project_id: null,
            questionData: {},
            answers: [],
            projLocatn: [],
        }
    },
    metaInfo: {
        title: 'SILOHO',
        titleTemplate: '%s | Profile',
    },
    created() {
        this.registerStoreModule('UserProfileModule', UserProfileModule)
        this.fetchProjectQuestions()
            .then(response => {
                this.storeQuestionAnswers(response)
                setTimeout(() => {
                    this.loaderShow = false
                }, 100)
            })
            .catch(res => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 100)
            })
    },
    computed: {
        ...mapState({
            projectQuestions: state => state.UserProfileModule.projectQuestions,
            projectLocation: state => state.UserProfileModule.projectLocation,
        }),
    },
    watch: {
        email(e) {
            var email = new RegExp(
                /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5})$/,
            )

            if (email.test(e)) {
                this.emailErr = false
                this.emailmsg = ''
            } else if (e == null) {
                this.emailErr = false
                this.emailmsg = 'enter email'
            } else {
                this.emailErr = true
                this.emailmsg = 'Please enter valid email'
            }

            this.isValidated()
        },
    },
    methods: {
        ...mapActions({
            fetchProjectQuestions: 'fetchProjectQuestions',
            deleteProject: 'deleteProject',
            doUpdateUserData: 'doUpdateUserData',
            fetchProjectLocation: 'fetchProjectLocation',
            updateProjectQuestion: 'updateProjectQuestion',
        }),
        openDeleteProject(id) {
            this.showDeleteProject = true
            document.querySelector('body').style.overflow = 'hidden'
            this.project_id = id
        },
        closeDeleteProject() {
            this.showDeleteProject = false
            document.querySelector('body').style.overflow = 'auto'
        },
        storeQuestionAnswers(response) {
            this.answers = []

            response.payload.questions.forEach(question => {
                if (question.answer_data.answered == true) {
                    switch (question.question_data.question_type) {
                        case 'TB':
                            this.answers.push(
                                question.answer_data.response.answer,
                            )
                            break
                        case 'CH':
                            question.question_data.choice_data.forEach(
                                choice => {
                                    if (
                                        choice.id ==
                                        question.answer_data.response
                                            .response_ids[0]
                                    ) {
                                        this.answers.push(choice.text)
                                    }
                                },
                            )
                            break
                        case 'RA':
                            this.answers.push(
                                'Range:- ₹' +
                                    this.convertToIndianNumberSystem(
                                        question.answer_data.response.min_range,
                                    ) +
                                    ' - ₹' +
                                    this.convertToIndianNumberSystem(
                                        question.answer_data.response.max_range,
                                    ),
                            )
                            break
                        case 'LC':
                            if (question.answer_data.response.house_number) {
                                this.answers.push(
                                    question.answer_data.response.project_name +
                                        ' |  ' +
                                        question.answer_data.response
                                            .house_number,
                                )
                            } else if (
                                question.answer_data.response.house_number ==
                                    '' &&
                                question.answer_data.response.project_name != ''
                            ) {
                                this.answers.push(
                                    question.answer_data.response.project_name,
                                )
                            }
                            break
                        // case 'FP':
                        //     this.answers.push(
                        //         question.answer_data.response.floor_plan +
                        //             ' | ' +
                        //             question.answer_data.response
                        //                 .floor_plan_type +
                        //             ' | ' +
                        //             question.answer_data.response
                        //                 .canonical_name,
                        //     )
                        //     break
                    }
                } else {
                    this.answers.push('Not Answered')
                }
            })
        },
        openProjectModal() {
            this.projectModal = true
            document.querySelector('body').style.overflow = 'hidden'
        },
        closeProjectModal() {
            this.projectModal = false
            document.querySelector('body').style.overflow = 'auto'
        },
        isValidated() {
            if (this.emailErr == false && this.email != null) {
                this.disabled = false
            } else {
                this.disabled = true
            }
        },
        updateProjectQuestions(obj) {
            this.updateProjectQuestion(obj).then(response => {
                if (response.responseCode == 200) {
                    this.fetchProjectQuestions().then(response => {
                        this.storeQuestionAnswers(response)
                    })
                    this.projLocatn = []
                }
            })
        },
        getProjectsOnLocation(location) {
            this.fetchProjectLocation(location).then(response => {
                this.projLocatn = this.projectLocation
            })
        },
        updateDetails() {
            this.doUpdateUserData({
                email_id: this.email,
            }).then(() => {
                this.email = null
                this.showModal = false
                this.$emit('updateData')
            })
        },
        closeEditUserModal() {
            this.showModal = false
            document.querySelector('body').style.overflow = 'auto'
        },
        editProject() {},
        deleteProjectResult() {
            this.closeDeleteProject()
            this.deleteProject(this.project_id).then(response => {
                if (response.responseCode == 200) {
                    this.$emit('updateData')
                }
            })
        },
        editProjectAnswer(obj) {
            this.editAnswer = true
            this.questionData = obj
            document.querySelector('body').style.overflow = 'hidden'
        },
        closeQuestionModal() {
            this.editAnswer = false
            document.querySelector('body').style.overflow = 'auto'
        },
        test() {
            //obtain a dom-reference for the image using getElementById
            //or by some other means depending on what you know of its HTML
            var img = document.getElementById('Test')
            console.log(img)
            var aspectRatio = img.clientWidth / img.clientHeight

            //if the img is more squat, set it's height equal to the target frame's height
            if (aspectRatio > 270 / 180) {
                img.style.height = 180 //image is
                console.log('this')
            }
            //otherwise the image is more vertically stretched
            // so set the img width equal to the target frame's width
            else {
                img.style.width = 270
                console.log('that')
            }
        },
        toggleThemeModal(bool, theme, description) {
            this.themeModal = bool
            if (bool == true) {
                this.themeModalName = theme
                this.themeModalDescription = description
            }
        },
    },
}
</script>

<style lang="scss">
@import './MyProfile.scss';
</style>
