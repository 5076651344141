<template>
    <div class="sqresult-container">
        <div class="sqresult-theme-desktop" >
            <div class="theme-details">
                <p class="heading">Your Main Style</p>
                <h1 class="theme-name">
                    {{ calculatedResult.primary_theme.name }}
                </h1>
                <p class="theme-description">
                    <template
                        v-if="
                            calculatedResult.primary_theme.description.length <
                                145
                        "
                    >
                        {{ calculatedResult.primary_theme.description }}
                    </template>
                    <template v-else>
                        {{
                            calculatedResult.primary_theme.description.slice(
                                0,
                                145,
                            )
                        }}...
                        <span
                            class="theme-see-more"
                            @click="
                                toggleThemeModal(
                                    true,
                                    calculatedResult.primary_theme.name,
                                    calculatedResult.primary_theme.description,
                                )
                            "
                        >
                            See more
                        </span>
                    </template>
                </p>
                <div class="secondary-themes">
                    <p class="similar-styles-heading">
                        You also like these styles
                    </p>
                    <!-- <div
                        v-for="theme in calculatedResult.secondary_theme"
                        :key="theme.theme_id"
                    > -->
                    <template v-if="calculatedResult.secondary_theme">
                        <h1 class="name">
                            {{ calculatedResult.secondary_theme.name }}
                        </h1>
                        <p class="description">
                            <template
                                v-if="
                                    calculatedResult.secondary_theme.description
                                        .length < 135
                                "
                            >
                                {{
                                    calculatedResult.secondary_theme.description
                                }}
                            </template>
                            <template v-else>
                                {{
                                    calculatedResult.secondary_theme.description.slice(
                                        0,
                                        135,
                                    )
                                }}...
                                <span
                                    class="theme-see-more"
                                    @click="
                                        toggleThemeModal(
                                            true,
                                            calculatedResult.secondary_theme
                                                .name,
                                            calculatedResult.secondary_theme
                                                .description,
                                        )
                                    "
                                >
                                    See more
                                </span>
                            </template>
                        </p>
                    </template>
                    <template v-if="calculatedResult.tertiary_theme">
                        <h1 class="name">
                            {{ calculatedResult.tertiary_theme.name }}
                        </h1>
                        <p class="description">
                            <template
                                v-if="
                                    calculatedResult.tertiary_theme.description
                                        .length < 135
                                "
                            >
                                {{
                                    calculatedResult.tertiary_theme.description
                                }}
                            </template>
                            <template v-else>
                                {{
                                    calculatedResult.tertiary_theme.description.slice(
                                        0,
                                        135,
                                    )
                                }}...
                                <span
                                    class="theme-see-more"
                                    @click="
                                        toggleThemeModal(
                                            true,
                                            calculatedResult.tertiary_theme
                                                .name,
                                            calculatedResult.tertiary_theme
                                                .description,
                                        )
                                    "
                                >
                                    See more
                                </span>
                            </template>
                        </p>
                    </template>
                    <!-- </div> -->
                </div>                
            </div>
            <div class="theme-image">
                <img
                    :src="calculatedResult.primary_theme.primary_image.w_image"
                />
            </div>
            <div class="theme-info">
                <p>Your style is  {{ calculatedResult.primary_theme.name }}</p>
                <a :href="url" class="retake-quiz">
                    Retake
                </a>
            </div>
        </div>
        <!-- <div class="sqresult-theme-mobile" v-else>
            <div class="theme-details">
                <p class="heading">Your Main Style</p>
                <h1 class="theme-name">
                    {{ calculatedResult.primary_theme.name }}
                </h1>
                <img
                    :src="calculatedResult.primary_theme.primary_image.l_image"
                    class="theme-image"
                />
                <p class="about-style">About your style</p>
                <p class="theme-description">
                    {{ calculatedResult.primary_theme.description }}
                </p>
                <div
                    class="secondary-themes"
                    v-if="calculatedResult.secondary_theme.length > 0"
                >
                    <p class="similar-styles-heading">
                        You also like these styles
                    </p>
                    <div
                        v-for="theme in calculatedResult.secondary_theme"
                        :key="theme.theme_id"
                    >
                        <h1 class="name">{{ theme.theme }}</h1>
                        <p class="description">
                            {{ theme.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import Button from 'componentsv2/Button'

export default {
    name: 'SQResultContainer',
    components: {
        //Button,
    },
    props: {
        calculatedResult: {
            type: Object,
        },
        windowWidth: Number,
        toggleThemeModal: Function,
        url: String,
    },
    data() {
        return {}
    },
    methods: {
        // toggleThemeModal() {},
    },
}
</script>

<style lang="scss" scoped>
@import './SQResultContainer.scss';
</style>
