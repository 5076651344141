<template>
    <div class="container">
        <div class="slider-track"></div>
        <input type="range" :min="min" :max="max" step="100000" :value="min_value?min_value:500000" id="slider-1" @input="slideOne">
        <input type="range" :min="min" :max="max" step="100000" :value="max_value?max_value:1200000" id="slider-2" @input="slideTwo">
    </div>
</template>

<script>
export default {
    name: 'Range', 
    props:['min','max','min_value','max_value'],
    data(){
        return{
            
        }
    },
    mounted(){
        // this.slideOne()
        // this.slideTwo()
        this.fillColor()
    },

    methods:{
        slideOne(){
            let sliderOne = document.getElementById("slider-1")
            let sliderTwo = document.getElementById("slider-2")
            let minGap = 500000

            if(parseInt(sliderTwo.value) - parseInt(sliderOne.value) <= minGap){
                sliderOne.value = parseInt(sliderTwo.value) - minGap
            }

            this.fillColor()
            this.$emit('min',sliderOne.value)
        },

        slideTwo(){
            let sliderOne = document.getElementById("slider-1")
            let sliderTwo = document.getElementById("slider-2")
            let minGap = 500000
            if(parseInt(sliderTwo.value) - parseInt(sliderOne.value) <= minGap){
                sliderTwo.value = parseInt(sliderOne.value) + minGap
            }
            this.fillColor()
            this.$emit('max',sliderTwo.value)
        },

        fillColor(){
            let sliderOne = document.getElementById("slider-1")
            let sliderTwo = document.getElementById("slider-2")
            let sliderTrack = document.querySelector(".slider-track")
            let sliderMaxValue = document.getElementById("slider-1").max
            let percent1 = (sliderOne.value / sliderMaxValue) * 100
            let percent2 = (sliderTwo.value / sliderMaxValue) * 100
            sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #ff6100 ${percent1}% , #ff6100 ${percent2}%, #dadae5 ${percent2}%)`;
        },
    }
}
</script>
<style lang="scss">
@import './Range.scss';
</style>
