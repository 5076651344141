import { updateUserData, getProject, getProjectLocation, updateProject, deleteProject, getProjectQuestions } from 'api/userApi'

const UserData = {
    state: () => ({
        projectData: [],
        projectLocation: [],
        projectQuestions: {},
    }),

    mutations: {
        setProjectData(state, payload) {
            state.projectData = payload
        },
        setProjectLocation(state, payload) {
            state.projectLocation = payload
        },
        setProjectQuestions(state, payload) {
            state.projectQuestions = payload
        }
    },

    getters: {

    },

    actions: {
        fetchProjectQuestions({ commit }) {
            return getProjectQuestions()
                .then(response => {
                    if (response.responseCode != 200) {
                        console.log('fetching failed')
                    } else {
                        commit('setProjectQuestions', response)
                        return response
                    }
                })
        },

        async doUpdateUserData({ commit }, obj) {
            return await updateUserData(obj)
        },

        async updateProjectQuestion({ commit }, obj) {
            return await updateProject(obj)
        },
        async deleteProject({ commit }, id) {
            return await deleteProject(id)
        },

        fetchProject({ commit }) {
            return getProject().then(response => {
                if (response.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setProjectData', response.payload)
                    return response
                }
            })
        },
        fetchProjectLocation({ commit }, location) {
            return getProjectLocation(location).then(response => {
                if (response.responseCode == 200) {
                    commit('setProjectLocation', response.payload)
                    return response.payload
                } else {
                    console.log('fetching failed')
                }
            })
        },
    }
}

export default UserData