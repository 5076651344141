var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sqresult-container"},[_c('div',{staticClass:"sqresult-theme-desktop"},[_c('div',{staticClass:"theme-details"},[_c('p',{staticClass:"heading"},[_vm._v("Your Main Style")]),_c('h1',{staticClass:"theme-name"},[_vm._v(" "+_vm._s(_vm.calculatedResult.primary_theme.name)+" ")]),_c('p',{staticClass:"theme-description"},[(
                        _vm.calculatedResult.primary_theme.description.length <
                            145
                    )?[_vm._v(" "+_vm._s(_vm.calculatedResult.primary_theme.description)+" ")]:[_vm._v(" "+_vm._s(_vm.calculatedResult.primary_theme.description.slice( 0, 145 ))+"... "),_c('span',{staticClass:"theme-see-more",on:{"click":function($event){return _vm.toggleThemeModal(
                                true,
                                _vm.calculatedResult.primary_theme.name,
                                _vm.calculatedResult.primary_theme.description
                            )}}},[_vm._v(" See more ")])]],2),_c('div',{staticClass:"secondary-themes"},[_c('p',{staticClass:"similar-styles-heading"},[_vm._v(" You also like these styles ")]),(_vm.calculatedResult.secondary_theme)?[_c('h1',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.calculatedResult.secondary_theme.name)+" ")]),_c('p',{staticClass:"description"},[(
                                _vm.calculatedResult.secondary_theme.description
                                    .length < 135
                            )?[_vm._v(" "+_vm._s(_vm.calculatedResult.secondary_theme.description)+" ")]:[_vm._v(" "+_vm._s(_vm.calculatedResult.secondary_theme.description.slice( 0, 135 ))+"... "),_c('span',{staticClass:"theme-see-more",on:{"click":function($event){return _vm.toggleThemeModal(
                                        true,
                                        _vm.calculatedResult.secondary_theme
                                            .name,
                                        _vm.calculatedResult.secondary_theme
                                            .description
                                    )}}},[_vm._v(" See more ")])]],2)]:_vm._e(),(_vm.calculatedResult.tertiary_theme)?[_c('h1',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.calculatedResult.tertiary_theme.name)+" ")]),_c('p',{staticClass:"description"},[(
                                _vm.calculatedResult.tertiary_theme.description
                                    .length < 135
                            )?[_vm._v(" "+_vm._s(_vm.calculatedResult.tertiary_theme.description)+" ")]:[_vm._v(" "+_vm._s(_vm.calculatedResult.tertiary_theme.description.slice( 0, 135 ))+"... "),_c('span',{staticClass:"theme-see-more",on:{"click":function($event){return _vm.toggleThemeModal(
                                        true,
                                        _vm.calculatedResult.tertiary_theme
                                            .name,
                                        _vm.calculatedResult.tertiary_theme
                                            .description
                                    )}}},[_vm._v(" See more ")])]],2)]:_vm._e()],2)]),_c('div',{staticClass:"theme-image"},[_c('img',{attrs:{"src":_vm.calculatedResult.primary_theme.primary_image.w_image}})]),_c('div',{staticClass:"theme-info"},[_c('p',[_vm._v("Your style is "+_vm._s(_vm.calculatedResult.primary_theme.name))]),_c('a',{staticClass:"retake-quiz",attrs:{"href":_vm.url}},[_vm._v(" Retake ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }