<template>
    <div 
        class="radio-button"
        :class="{
            'radio-button-active': selected
        }"
    >
        <div 
            class="circle"
            :class="{
                'circle-active': selected
            }"
        >

        </div>
        {{name}}
    </div>
</template>

<script>
export default {
    name: 'RadioButton', 
    components:{
    },
    props:{
        name:{
            type: String
        },
        selected:{
            type: Boolean
        }
    }
    
}
</script>
<style lang="scss">
@import './RadioButton.scss';
</style>
