<template>
    <div>
        <div class="question-container-wrapper">
            <div class="question-container">
                <div class="question-cols">
                    <div class="col-left">
                        <div class="content-left">
                            <h1 class="Heading">
                                {{ question.question_data.text }}
                            </h1>
                            <p
                                class="subHeading"
                                v-if="question.question_data.subtext"
                            >
                                {{ question.question_data.subtext }}
                            </p>
                        </div>
                    </div>
                    <div class="col-right">
                        <template
                            v-if="question.question_data.question_type == 'TB'"
                        >
                            <div class="question-tb">
                                <Input
                                    v-model="text"
                                    :labelName="textboxName"
                                    placeholder="Type here"
                                    :error="textErr"
                                    :msg="textmsg"
                                    :noerror="true"
                                    @input="checkInputText"
                                />
                            </div>
                        </template>
                        <template
                            v-if="question.question_data.question_type == 'CH'"
                        >
                            <div class="selectchoice">
                                <div class="selectchoice-row">
                                    <div
                                        class="selectchoice-img-container"
                                        :id="choice.id"
                                        v-for="choice in question.question_data
                                            .choice_data"
                                        :key="choice.id"
                                        @click="select"
                                    >
                                        <template v-if="answer == null">
                                            <ChoiceCard
                                                :id="'choice' + choice.id"
                                                :imgsrc="
                                                    choice.choice_image
                                                        ? choice.choice_image
                                                        : ''
                                                "
                                                :labelName="choice.text"
                                                :choiceID="choice.id"
                                            />
                                        </template>
                                        <template v-else>
                                            <template
                                                v-if="answer == choice.id"
                                            >
                                                <ChoiceCard
                                                    :id="'choice' + choice.id"
                                                    :imgsrc="
                                                        choice.choice_image
                                                            ? choice.choice_image
                                                            : ''
                                                    "
                                                    :labelName="choice.text"
                                                    :choiceID="choice.id"
                                                    selected
                                                />
                                            </template>
                                            <template v-else>
                                                <ChoiceCard
                                                    :id="'choice' + choice.id"
                                                    :imgsrc="
                                                        choice.choice_image
                                                            ? choice.choice_image
                                                            : ''
                                                    "
                                                    :labelName="choice.text"
                                                    :choiceID="choice.id"
                                                />
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template
                            v-if="question.question_data.question_type == 'RA'"
                        >
                            <div class="price-range">
                                <h2>Your Price Range</h2>
                                <p>
                                    ₹ {{ convertToIndianNumberSystem(min) }} - ₹
                                    {{ convertToIndianNumberSystem(max) }}
                                </p>
                                <template v-if="rangeAnswer != null">
                                    <Range
                                        v-if="rangeChoices[0]"
                                        :min="rangeChoices[0].min_value"
                                        :max="rangeChoices[0].max_value"
                                        :min_value="rangeAnswer.min"
                                        :max_value="rangeAnswer.max"
                                        @min="updatemin"
                                        @max="updatemax"
                                    />
                                </template>
                                <template v-else>
                                    <Range
                                        v-if="rangeChoices[0]"
                                        :min="rangeChoices[0].min_value"
                                        :max="rangeChoices[0].max_value"
                                        @min="updatemin"
                                        @max="updatemax"
                                    />
                                </template>
                            </div>
                        </template>
                        <template
                            v-if="question.question_data.question_type == 'LC'"
                        >
                            <div class="project-details">
                                <div class="project-details-1" v-if="PD">
                                    <p>Your Location</p>
                                    <div class="radio-button-wrapper">
                                        <template
                                            v-if="
                                                projectAnswer.projectLocation !=
                                                    ''
                                            "
                                        >
                                            <RadioButton
                                                v-for="(choice,
                                                index) in projectChoices"
                                                :name="choice.text"
                                                :key="`${choice.text}_${index}`"
                                                class="radio"
                                                :id="`${choice.text}`"
                                                @click.native="
                                                    selectedOneProjectChoices
                                                "
                                                :selected="
                                                    projectAnswer.projectLocation ==
                                                    choice.text
                                                        ? true
                                                        : false
                                                "
                                            />
                                        </template>
                                        <template v-else>
                                            <RadioButton
                                                v-for="(choice,
                                                index) in projectChoices"
                                                :name="choice.text"
                                                :key="`${choice.text}_${index}`"
                                                class="radio"
                                                :id="`${choice.text}`"
                                                @click.native="
                                                    selectedOneProjectChoices
                                                "
                                            />
                                        </template>
                                    </div>
                                    <div v-if="projectLocations.length > 0">
                                        <div class="project-select">
                                            <div class="project-dropdown">
                                                <template
                                                    v-if="
                                                        projectAnswer.projectName !=
                                                            '' &&
                                                            projectAnswer.houseNumber ==
                                                                ''
                                                    "
                                                >
                                                    <Select
                                                        :choices="
                                                            projectLocations
                                                        "
                                                        @valueEmit="address"
                                                        :default-select="
                                                            projectAnswer.projectName
                                                        "
                                                    />
                                                </template>
                                                <template v-else>
                                                    <Select
                                                        :choices="
                                                            projectLocations
                                                        "
                                                        @valueEmit="address"
                                                    />
                                                </template>
                                            </div>
                                            <div class="house-number">
                                                <Input
                                                    v-model="houseNumber"
                                                    labelName="House Number"
                                                    :error="hNoErr"
                                                    :msg="hNomsg"
                                                    :noerror="true"
                                                />
                                            </div>
                                        </div>
                                        <div class="change">
                                            <h1>
                                                Could not find your project or
                                                location?
                                            </h1>
                                            <p @click="changeUI1">
                                                Enter Your Address Here Instead
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="project-details-2" v-else>
                                    <Input
                                        v-model="enteredAddress"
                                        labelName="Your House Address"
                                        :error="addrErr"
                                        :msg="addrmsg"
                                        :noerror="true"
                                        class="house-number"
                                    />
                                    <div class="change">
                                        <h1>Clicked it by mistake?</h1>
                                        <p @click="changeUI2">
                                            Find Your Project Here
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="question-footer">
                    <Button
                        class="save-button"
                        primary
                        name="Save Changes"
                        @click.native="updateDetails"
                        :disabled="disabled"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'componentsv2/Button'
import Input from 'componentsv2/Input'
import ChoiceCard from 'componentsv2/ChoiceCard'
import ConvertToPrice from 'mixins/ConvertToPrice'
import Select from 'componentsv2/Select'
import Range from 'componentsv2/Range'
import RadioButton from 'componentsv2/RadioButton'

export default {
    name: 'QuestionContainer',
    components: {
        Button,
        Input,
        ChoiceCard,
        Range,
        Select,
        RadioButton,
    },
    props: {
        question: {
            type: Object,
        },
        projectLocations: {
            type: Array,
        },
    },
    mixins: [ConvertToPrice],
    data() {
        return {
            answer: null,
            rangeAnswer: {
                min: this.question.answer_data.response.min_range,
                max: this.question.answer_data.response.max_range,
            },
            projectAnswer: {
                projectLocation: this.question.answer_data.response
                    .project_location,
                houseNumber: this.question.answer_data.response.house_number,
                projectName: this.question.answer_data.response.project_name,
                projectFloorplan: this.question.answer_data.response.floor_plan,
            },
            projectChoices: this.question.question_data.choice_data,
            selectedProjectChoices: [],
            PD: true,
            projectName: '',
            houseNumber: '',
            enteredAddress: '',
            hNoErr: null,
            hNomsg: '',
            addrErr: null,
            addrmsg: '',
            disabled: true,
            selectedchoices: [],
            rangeChoices: [],
            min: this.question.answer_data.response.min_range,
            max: this.question.answer_data.response.max_range,
            textboxName: '',
            text: '',
            textErr: false,
            textmsg: '',
        }
    },
    created() {
        if (this.question.question_data.question_type == 'RA') {
            this.rangeChoices = this.question.question_data.choice_data
            this.rangeAnswer.min = this.question.answer_data.response.min_range
            this.rangeAnswer.max = this.question.answer_data.response.max_range
        } else if (this.question.question_data.question_type == 'CH') {
            this.answer = this.question.answer_data.response.response_ids
        } else if (this.question.question_data.question_type == 'TB') {
            this.textboxName = this.question.answer_data.response.question
            this.text = this.question.answer_data.response.answer
        } else {
            if (
                this.question.answer_data.response.house_number == '' &&
                this.question.answer_data.response.project_name != ''
            ) {
                this.PD = false
                this.enteredAddress = this.question.answer_data.response.project_name
            } else {
                this.PD = true
                this.$emit(
                    'getProjectsOnLocation',
                    this.projectAnswer.projectLocation,
                )
                this.houseNumber = this.question.answer_data.response
                    .house_number
                    ? this.question.answer_data.response.house_number
                    : ''
                this.disabled = true
            }
        }
    },
    watch: {
        houseNumber(e) {
            var check = e.replace(/\s+/g, '')
            if (check.length != 0) {
                this.hNoErr = false
                this.hNomsg = ''
            } else {
                this.hNoErr = true
                this.hNomsg = 'Please enter valid house number'
            }
        },
        enteredAddress(e) {
            var check = e.replace(/\s+/g, '')
            if (check.length != 0) {
                this.addrErr = false
                this.addrmsg = ''
                this.disabled = false
            } else {
                this.addrErr = true
                this.addrmsg = 'Please enter valid house number'
                this.disabled = true
            }
        },
        location: function() {
            if (this.projectName != '' && this.houseNumber != '') {
                this.disabled = false
            } else {
                this.disabled = true
            }
        },
    },
    computed: {
        location() {
            return this.projectName + '|' + this.houseNumber
        },
    },
    methods: {
        updateDetails() {
            this.$emit('closeModal')
            let response = []
            if (this.question.question_data.question_type == 'RA') {
                response.push({
                    response_choice_id: null,
                    response_choice_text: this.min + '|' + this.max,
                })
            } else if (this.question.question_data.question_type == 'CH') {
                this.selectedchoices.forEach(selectedchoice => {
                    response.push({
                        response_choice_id: selectedchoice,
                        response_choice_text: null,
                    })
                })
            } else if (this.question.question_data.question_type == 'TB') {
                response.push({
                    response_choice_id: this.question.question_data
                        .choice_data[0].id,
                    response_choice_text: this.text,
                })
            } else if (this.question.question_data.question_type == 'LC') {
                if (this.PD == true) {
                    response.push({
                        response_choice_id: null,
                        response_choice_text: this.location,
                    })
                } else {
                    response.push({
                        response_choice_id: null,
                        response_choice_text: this.enteredAddress,
                    })
                }
            } else {
                response = []
            }
            this.$emit('updateQuestions', {
                question_id: this.question.question_data.id,
                response_choices: response,
            })
        },

        checkInputText(e) {
            if (this.textboxName == 'Name') {
                var name = new RegExp('^[a-zA-Z_ ]*$')
                var check = e.replace(/\s+/g, '')
                if (name.test(e) && check.length != 0) {
                    this.textErr = false
                    this.textmsg = ''
                    this.disabled = false
                } else {
                    this.textErr = true
                    this.textmsg = 'Please enter name'
                    this.disabled = true
                }
            } else {
                var email = new RegExp(
                    /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,5})$/,
                )
                if (email.test(e)) {
                    this.textErr = false
                    this.textmsg = ''
                    this.disabled = false
                } else {
                    this.textErr = true
                    this.textmsg = 'Please enter email'
                    this.disabled = true
                }
            }
        },

        updatemin(e) {
            this.min = e
            this.disabled = false
        },

        updatemax(e) {
            this.max = e
            this.disabled = false
        },
        changeNumberFormat(number, decimals) {
            const decimalPoints = decimals || 2
            const noOfLakhs = number / 100000
            let displayStr
            const roundOf = integer => {
                return +integer.toLocaleString(undefined, {
                    minimumFractionDigits: decimalPoints,
                    maximumFractionDigits: decimalPoints,
                })
            }
            if (noOfLakhs >= 1 && noOfLakhs <= 99) {
                const lakhs = roundOf(noOfLakhs)
                displayStr = `${lakhs} L`
            } else if (noOfLakhs >= 100) {
                const crores = roundOf(noOfLakhs / 100)
                const crorePrefix =
                    crores >= 100000
                        ? this.changeNumberFormat(crores, decimals, true)
                        : crores
                displayStr = `${crorePrefix} Cr`
            } else {
                displayStr = roundOf(+number)
            }

            return displayStr
        },
        select(selected) {
            return this.question.question_data.is_multi_ans == true
                ? this.selectedMultiChoices(selected)
                : this.selectedOneChoices(selected)
        },
        selectedMultiChoices(selected) {
            const answeredQuestion = document.getElementById(
                `choice${this.answer}`,
            )
            answeredQuestion.classList.remove('selected-choicecard')
            answeredQuestion.childNodes[1].classList.remove('show')
            selected.currentTarget
                .querySelector('.choicecard-icon-container')
                .classList.toggle('show')
            selected.currentTarget
                .querySelector('.choicecard')
                .classList.toggle('selected-choicecard')
            let targetId = event.currentTarget.id

            if (this.selectedchoices.includes(targetId)) {
                const index = this.selectedchoices.indexOf(targetId)
                if (index > -1) {
                    this.selectedchoices.splice(index, 1)
                }
            } else {
                this.selectedchoices.push(targetId)
            }

            if (this.selectedchoices.length > 0) {
                this.disabled = false
            } else {
                this.disabled = true
            }
        },

        selectedOneChoices(selected) {
            const answeredQuestion = document.getElementById(
                `choice${this.answer}`,
            )
            answeredQuestion.classList.remove('selected-choicecard')
            answeredQuestion.childNodes[1].classList.remove('show')
            if (this.selectedchoices.length > 0) {
                const previousselected = document.getElementById(
                    `choice${this.selectedchoices[0]}`,
                )
                previousselected.classList.remove('selected-choicecard')
                previousselected.childNodes[1].classList.remove('show')
            }

            selected.currentTarget
                .querySelector('.choicecard-icon-container')
                .classList.add('show')
            selected.currentTarget
                .querySelector('.choicecard')
                .classList.add('selected-choicecard')
            this.selectedchoices.length = 0
            this.selectedchoices.push(selected.currentTarget.id)

            if (this.selectedchoices.length > 0) {
                this.disabled = false
            } else {
                this.disabled = true
            }
        },

        changeUI1() {
            this.PD = false
            this.selectedProjectChoices = []
            this.disabled = true
            this.houseNumber = ''
            this.projectName = ''
            this.projectAnswer = []
            this.projectLocations = []
        },

        changeUI2() {
            this.PD = true
            this.selectedProjectChoices = []
            this.disabled = true
            this.houseNumber = ''
        },

        selectedOneProjectChoices(selected) {
            this.projectName = ''
            this.houseNumber = ''
            this.projectAnswer = {}
            if (this.selectedProjectChoices.length > 0) {
                const previousselected = document.getElementById(
                    `${this.selectedProjectChoices[0]}`,
                )
                previousselected.classList.remove('radio-button-active')
                previousselected.childNodes[0].classList.remove('circle-active')
            }

            selected.currentTarget.classList.add('radio-button-active')
            selected.currentTarget
                .querySelector('.circle')
                .classList.add('circle-active')
            this.selectedProjectChoices.length = 0
            this.selectedProjectChoices.push(selected.currentTarget.id)
            this.getProjects(this.selectedProjectChoices[0])
        },

        getProjects(location) {
            this.$emit('getProjectsOnLocation', location)
            this.disabled = true
        },

        address(value) {
            this.projectName = value
        },
    },
}
</script>
<style lang="scss">
@import './QuestionContainer.scss';
</style>
