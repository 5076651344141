<template>
    
    <div class="select">
        <template v-if="floorplan==true">
            <label>Your Floorplan</label>
            <select @change="emitSelectedFloorplan" id="select_floorplan">
                <option value="" :selected="defaultSelect?false:true">Please Select Your Floorplan</option>
                <option v-for="choice in choices" :key="choice.id" :value="choice.name" :selected="defaultSelect==choice.name?true:false">{{choice.name}}</option>
            </select>
        </template>
        <template v-else>
            <label>Your Project</label>
            <select @change="emitSelected" id="select">
                <option value="" :selected="defaultSelect?false:true">Please Select Your Project</option>
                <option v-for="choice in choices" :key="choice.id" :value="choice.project_name" :selected="defaultSelect==choice.project_name?true:false">{{choice.project_name}}</option>
            </select>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Select', 
    props:{
        choices:{
            type: Array
        },
        defaultSelect: String,
        floorplan:Boolean,
    },
    mounted(){
        this.choices.forEach((choice)=>{
            if(this.floorplan==false){
                if(choice.project_name==this.defaultSelect){
                this.emitSelected() 
                } 
            }else{
                if(choice.name==this.defaultSelect){
                    this.emitSelectedFloorplan()
                }
            }
        })
    },
    methods:{
        emitSelected(){
            let select = document.getElementById('select');
            let value = select.options[select.selectedIndex].value;
            if(value!=""){
                this.$emit('valueEmit', value)
            }else{
                this.$emit('valueEmit', '')
            }
        },
        emitSelectedFloorplan(){
            let select = document.getElementById('select_floorplan');
            let value = select.options[select.selectedIndex].value;
            if(value!=""){
                this.$emit('valueEmit', value)
            }else{
                this.$emit('valueEmit', '')
            }
        }
    }
}
</script>
<style lang="scss">
@import './Select.scss';
</style>
